import { Menu, RadioGroup } from '@canvaza/serval-ui';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import type { Timestamp } from 'firebase/firestore';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

import LightBlueButton from '@/common/component/button/LightBlueButton';
import { useBulkDeleteForeverNotifications } from '@/shared/firebase/client/hooks';
import type { INotification } from '@/shared/firebase/models';

export type IClearBy = 'today' | 'yesterday' | 'week' | 'month' | 'all';

export type IClearDateOption = {
  id: string;
  label: string;
  value: IClearBy;
  count: number;
  defaultChecked?: boolean;
  disabled?: boolean;
};

type NotificationClearDropdownProps = {
  notifications: INotification[];
};
export default function NotificationClearDropdown({
  notifications,
}: NotificationClearDropdownProps) {
  const [clearDateOption, setClearDateOption] = useState<IClearDateOption[]>([
    {
      id: 'today',
      label: 'Today',
      value: 'today',
      count: 0,
      defaultChecked: true,
    },
    {
      id: 'yesterday',
      label: 'Yesterday',
      value: 'yesterday',
      count: 0,
    },

    {
      id: 'week',
      label: 'This week',
      value: 'week',
      count: 0,
    },
    {
      id: 'month',
      label: 'This month',
      value: 'month',
      count: 0,
    },
    {
      id: 'all',
      label: 'All time',
      value: 'all',
      count: 0,
    },
  ]);
  const [filteredIds, setFilteredIds] = useState<string[]>([]);
  const [dateToBeClear, setDateToBeClear] = useState<IClearBy>('today');
  const [moments, setMoments] = useState({
    today: '',
    yesterday: '',
    weekAgo: '',
    monthAgo: '',
  });

  const handleRadioButtonChange = (option: IClearDateOption) => {
    setDateToBeClear(option.value);
  };

  const {
    batchDelete: batchDeleteNotifications,
    state: batchDeleteNotificationsState,
  } = useBulkDeleteForeverNotifications(filteredIds);

  const handleClearNotification = () => {
    if (filteredIds.length === 0) return;
    batchDeleteNotifications();
  };

  const filteredByCategory = (lastDate: string) => {
    return notifications
      .filter((notification) =>
        moment((notification.createdAt as Timestamp).toDate()).isBetween(
          lastDate,
          moment.now()
        )
      )
      .map((notification) => notification.id);
  };
  const setNotification = (lastDate: string) => {
    const updateFilteredIds = filteredByCategory(lastDate);

    setFilteredIds(updateFilteredIds);
  };

  const { today, yesterday, weekAgo, monthAgo } = moments;

  useEffect(() => {
    setMoments({
      today: moment().subtract(1, 'day').format('YYYY-MM-DD HH:mm:ss'),
      yesterday: moment().subtract(2, 'days').format('YYYY-MM-DD HH:mm:ss'),
      weekAgo: moment().subtract(1, 'weeks').format('YYYY-MM-DD HH:mm:ss'),
      monthAgo: moment().subtract(1, 'months').format('YYYY-MM-DD HH:mm:ss'),
    });
  }, []);

  useEffect(() => {
    switch (dateToBeClear) {
      case 'all':
        setFilteredIds(notifications.map((notification) => notification.id));
        break;
      case 'month':
        setNotification(monthAgo);
        break;
      case 'week':
        setNotification(weekAgo);
        break;
      case 'yesterday':
        setNotification(yesterday);
        break;
      case 'today':
        setNotification(today);
        break;
      default:
        setFilteredIds([]);
    }
  }, [dateToBeClear, notifications]);

  useEffect(() => {
    const updateClearDateOptions = [...clearDateOption].map((option) => ({
      ...option,
      count: (() => {
        if (option.value === 'all') {
          return notifications.length;
        }
        if (option.value === 'month') {
          return filteredByCategory(monthAgo).length;
        }
        if (option.value === 'week') {
          return filteredByCategory(weekAgo).length;
        }
        if (option.value === 'yesterday') {
          return filteredByCategory(yesterday).length;
        }
        if (option.value === 'today') {
          return filteredByCategory(today).length;
        }
        return 0;
      })(),
      disabled: (() => {
        const isAllEnabled = notifications.length === 0;
        const isMonthAgoEnabled =
          isAllEnabled && filteredByCategory(monthAgo).length === 0;
        const isWeeklyEnabled =
          isAllEnabled && filteredByCategory(weekAgo).length === 0;
        const isYesterdayEnabled =
          isAllEnabled && filteredByCategory(yesterday).length === 0;
        const isTodayEnabled =
          isAllEnabled && filteredByCategory(today).length === 0;
        if (option.value === 'all') {
          return isAllEnabled;
        }
        if (option.value === 'month') {
          return isMonthAgoEnabled;
        }
        if (option.value === 'week') {
          return isWeeklyEnabled;
        }
        if (option.value === 'yesterday') {
          return isYesterdayEnabled;
        }
        if (option.value === 'today') {
          return isTodayEnabled;
        }
        return false;
      })(),
    }));

    setClearDateOption(updateClearDateOptions);
  }, [notifications]);

  return (
    <Menu
      label="Clear"
      icon={<ChevronDownIcon className="w-3 stroke-2 md:w-4" />}
      placement="bottom-end"
      className="rounded-xl border-2 border-primary/30 p-2 text-sm sm:px-3 sm:py-2"
    >
      <div className="flex w-52 flex-col gap-4 rounded-md p-4 text-sm">
        <h2 className="font-semibold">Clear notification</h2>
        <RadioGroup
          name="clear_date"
          options={clearDateOption.map((option) => ({
            ...option,
            id: option.id,
            label: `${option.label}`,
            onChange: () => handleRadioButtonChange(option),
          }))}
        />
        <div className="flex items-center justify-center border-t-2 border-primary-light/25 px-2 pt-4">
          <LightBlueButton
            onClick={handleClearNotification}
            isLoading={batchDeleteNotificationsState.isLoading}
            disabled={
              batchDeleteNotificationsState.isLoading ||
              filteredIds.length === 0
            }
            title="Clear"
            fullWidth
          />
        </div>
      </div>
    </Menu>
  );
}
