import { Tab } from '@headlessui/react';
import React from 'react';

type ContentProps = {
  tabContents: {
    id: number;
    component: React.ReactNode;
  }[];
};
export default function Content({ tabContents }: ContentProps) {
  return (
    <Tab.Panels>
      {tabContents.map((val, index) => (
        <Tab.Panel key={index}>{val.component}</Tab.Panel>
      ))}
    </Tab.Panels>
  );
}
