/* eslint-disable no-nested-ternary */
import { Tab } from '@headlessui/react';
import { Cog6ToothIcon, XMarkIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import Link from 'next/link';
import React from 'react';

import { NOTIFICATION_SETTING } from '@/shared/config/route-links.config';
import type { FilterNotificationBy } from '@/shared/firebase/client/hooks';
import type { INotification } from '@/shared/firebase/models/notification.interface';

import type { NotificationType } from '..';
import NotificationClearDropdown from './NotificationClearDropdown';

const dropdownSelected =
  'rounded-lg bg-primary rounded-md px-3 py-1 font-bold dark:text-dark-lighter text-dark-lighter dark:hover:bg-primary-lighter';
const dropdownNotSelected =
  'font-bold hover:text-dark-darker font-normal rounded-md px-3 py-1 hover:bg-dark-200/60 dark:hover:text-dark-lighter dark:hover:bg-dark-500';
const pageSelected =
  'border-b-2 border-dark-darker px-0 py-2 font-bold text-dark-darker dark:text-dark-lighter dark:border-dark-lighter dark:text-dark-lighter';
const pageNotSelected =
  'bg-dark-lighter font-normal hover:text-dark-darker dark:bg-dark-600 dark:hover:bg-dark-600 dark:hover:text-dark-200 dark:text-dark-lighter';

type HeaderProps = {
  type: NotificationType;
  data: {
    id: number;
    title: React.ReactNode;
    filterByKey: string;
    type?: NotificationType;
    count?: number;
  }[];
  notifications: INotification[];
  onFilterBy: (filterBy: FilterNotificationBy) => void;
  onCloseDrawer?: () => void;
};
export default function Header({
  type,
  data,
  notifications,
  onFilterBy,
  onCloseDrawer,
}: HeaderProps) {
  return (
    <div className="h-auto pb-1">
      {type === 'dropdown' && (
        <div className="flex justify-between gap-2 px-0 py-3 text-lg font-semibold">
          <h1 className="dark:text-dark-200">Notifications</h1>
          {onCloseDrawer && (
            <button
              onClick={onCloseDrawer}
              className="flex items-center rounded-md bg-dark-200 p-0.5 duration-300 hover:bg-dark-200/50 dark:bg-dark-400/50 dark:hover:bg-dark-400"
            >
              <XMarkIcon className="w-5" />
            </button>
          )}
        </div>
      )}

      <div
        className={clsx({
          'flex flex-wrap gap-3 pb-2 sm:flex-row xs:justify-between':
            type === 'page',
          'flex flex-row justify-between gap-2': type === 'dropdown',
        })}
      >
        <Tab.List>
          <div
            className={clsx({
              'flex justify-between sm:px-3 px-0': type === 'page',
              'flex flex-col gap-2': type === 'dropdown',
            })}
          >
            <div
              className={clsx({
                'flex flex-row items-center sm:gap-5 sm:py-2 gap-1 px-0':
                  type === 'dropdown',
                'flex gap-7 sm:gap-11': type === 'page',
              })}
            >
              {data.map((page, index) => {
                return (
                  <div key={index} className="flex items-center sm:gap-2 ">
                    <Tab
                      key={index}
                      onClick={() =>
                        onFilterBy(page.filterByKey as FilterNotificationBy)
                      }
                      className={({ selected }) =>
                        clsx(
                          'w-full text-sm font-bold leading-5 dark:text-dark-200 dark:hover:bg-dark-500',
                          type === 'dropdown'
                            ? selected
                              ? dropdownSelected
                              : dropdownNotSelected
                            : selected
                            ? pageSelected
                            : pageNotSelected
                        )
                      }
                    >
                      {page.title}
                    </Tab>
                  </div>
                );
              })}
            </div>
          </div>
        </Tab.List>
        <div className="flex items-center">
          {type === 'page' && (
            <NotificationClearDropdown notifications={notifications} />
          )}
          {type === 'dropdown' && (
            <Link
              href={NOTIFICATION_SETTING.url}
              className="flex justify-end rounded-full hover:bg-dark-200 sm:py-1"
            >
              <Cog6ToothIcon className="h-7 hover:fill-dark-lighter" />
            </Link>
          )}
        </div>
      </div>
    </div>
  );
}
