import { useGetCommentById } from '@/shared/firebase/client/hooks';
import type { INotification } from '@/shared/firebase/models/notification.interface';

import Notification from '../Notification';
import NotificationListSkeleton from '../NotificationListSkeleton';

export default function Comment(notification: INotification) {
  const { data: comment, state: commentState } = useGetCommentById(
    notification.metadata?.objectId,
    'get-comment-by-id'
  );
  return (
    <>
      {commentState.isLoading && <NotificationListSkeleton />}
      {commentState.isSuccess && comment && (
        <Notification
          notification={notification}
          routeTo={`/item/${comment.itemId}?scrollToCommentId=${notification.metadata?.objectId}`}
        />
      )}
    </>
  );
}
