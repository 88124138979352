import { Tab } from '@headlessui/react';
import { BellSlashIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import type { FirestoreError } from 'firebase/firestore';
import React, { useMemo } from 'react';
import type { UseQueryResult } from 'react-query';

import EmptyPlaceholder from '@/common/component/placeholder/EmptyPlaceholder';
import type { INotification } from '@/shared/firebase';
import type { FilterNotificationBy } from '@/shared/firebase/client/hooks';
import { Collection } from '@/shared/firebase/constants';
import { useCurrentUser } from '@/shared/hooks/useCurrentUser';

import Content from './content';
import NotificationList from './content/NotificationList';
import Footer from './footer';
import Header from './header';
import type { NotificationTabPageType } from './page';

export type NotificationType = 'page' | 'dropdown';
type NotificationsProps = {
  type: NotificationType;
  notifications: INotification[];
  notificationState: Omit<
    UseQueryResult<INotification[], FirestoreError>,
    'data'
  >;
  onFilterBy: (filterBy: FilterNotificationBy) => void;
  onCloseDrawer?: () => void;
};
export default function Notifications({
  type,
  notifications,
  notificationState,
  onFilterBy,
  onCloseDrawer,
}: NotificationsProps) {
  const { currentUser } = useCurrentUser();

  const allNotifications = (): INotification[] => {
    // exclude invitations
    return notifications.filter(
      (val) =>
        (val.workspaceId === currentUser?.defaultWorkspaceId ||
          val.workspaceId === null) &&
        val.type !== Collection.INVITATIONS
    );
  };

  const pages: NotificationTabPageType[] = [
    {
      id: 1,
      title: <span>All</span>,
      component: (
        <NotificationList
          notifications={allNotifications()}
          isLoading={notificationState?.isLoading ?? false}
          type={type}
        />
      ),
      count: allNotifications().length,
      filterByKey: 'all',
    },

    {
      id: 2,
      title: <span>Unread</span>,
      component: (
        <NotificationList
          notifications={allNotifications()}
          isLoading={notificationState?.isLoading ?? false}
          type={type}
        />
      ),
      count: allNotifications().length,
      filterByKey: 'unread',
    },
    {
      id: 3,
      title: <span>Comments</span>,
      component: (
        <NotificationList
          notifications={allNotifications()}
          isLoading={notificationState?.isLoading ?? false}
          type={type}
        />
      ),
      count: allNotifications().length,
      filterByKey: Collection.COMMENTS,
    },
  ];

  const headerData = useMemo(
    () =>
      pages.map((page) => ({
        id: page.id,
        title: page.title,
        type: page.type,
        count: page.count,
        filterByKey: page.filterByKey,
      })),
    [pages]
  );

  const contentData = useMemo(
    () =>
      pages.map((page) => ({
        id: page.id,
        component: page.component,
        type: page.type,
      })),
    [pages]
  );

  return (
    <Tab.Group>
      <div
        className={clsx('flex h-full flex-col', {
          'justify-normal': type === 'dropdown',
          'justify-start': type === 'page',
        })}
      >
        <Header
          type={type}
          data={headerData}
          notifications={notifications}
          onFilterBy={onFilterBy}
          onCloseDrawer={onCloseDrawer}
        />

        {notificationState.isSuccess && notifications.length > 0 && (
          <Content tabContents={contentData} />
        )}

        {notificationState.isSuccess && allNotifications().length === 0 && (
          <div className="h-[60vh]">
            <EmptyPlaceholder
              title="No notification"
              description="You have no notification today"
              image={
                <BellSlashIcon className="w-20 stroke-dark-400 stroke-[0.5px] dark:stroke-dark-300" />
              }
            />
          </div>
        )}

        {allNotifications().length > 0 && (
          <Footer
            type={type}
            notifications={notifications}
            onCloseDrawer={onCloseDrawer}
          />
        )}
      </div>
    </Tab.Group>
  );
}
