import clsx from 'clsx';
import React from 'react';

import { Collection as CollectionConstant } from '@/shared/firebase/constants';
import type { INotification } from '@/shared/firebase/models/notification.interface';

import type { NotificationType } from '..';
import Comment from './category/Comment';
import Item from './category/Item';
import Subscription from './category/Subscription';
import WorkspaceMember from './category/WorkspaceMember';
import NotificationListSkeleton from './NotificationListSkeleton';

type NotificationListProps = {
  notifications: INotification[];
  isLoading: boolean;
  type?: NotificationType;
};
export default function NotificationList({
  notifications,
  isLoading,
  type,
}: NotificationListProps) {
  return (
    <div className="space-y-6">
      {isLoading && <NotificationListSkeleton />}
      {!isLoading && notifications.length > 0 && (
        <div
          className={clsx('mx-auto p-0 pt-3 text-sm', {
            'h-[77svh] sm:h-[82svh] overflow-y-auto flex flex-auto flex-col':
              type === 'dropdown',
            'h-full overflow-visible': type === 'page',
          })}
        >
          {notifications.map((notification) => {
            switch (notification.type) {
              case CollectionConstant.COMMENTS:
                return <Comment {...notification} />;
              case CollectionConstant.ITEMS:
                return <Item {...notification} />;
              case CollectionConstant.WORKSPACE_MEMBERS:
                return <WorkspaceMember {...notification} />;
              case CollectionConstant.SUBSCRIPTIONS:
                return <Subscription {...notification} />;
              default:
                return null;
            }
          })}
        </div>
      )}
    </div>
  );
}
