const STORAGE_BASE_URL =
  'https://firebasestorage.googleapis.com/v0/b/async-video.appspot.com/o';

export const CONSTANTS = {
  ITEMS_BASE_URL: `${STORAGE_BASE_URL}/files`,
  USERS_AVATARS_BASE_URL: `${STORAGE_BASE_URL}/avatars`,
  WORKSPACE_LOGO_BASE_URL: `${STORAGE_BASE_URL}/workspace-logo`,
  ITEMS_PATH: '/files',
  AVATARS_PATH: '/avatars',
  USE_SECURE_COOKIES: false,
  ITEM_NAME_PREFIX: 'canvaza',
  FIREBASE_AUTH_CALLBACK_URL:
    'https://async-video.firebaseapp.com/__/auth/handler',
};
