import { Avatar } from '@canvaza/serval-ui';
import type { Timestamp } from 'firebase/firestore';
import Link from 'next/link';
import React from 'react';

import { CONSTANTS } from '@/shared/config/constant';
import { Collection } from '@/shared/firebase/constants';
import type { INotification } from '@/shared/firebase/models';
import { formatDateLabel } from '@/shared/utils/date-formatter';
import { ReadStatusIcon } from '@/shared/utils/svg';

import Action from './Action';

type NotificationProps = {
  notification: INotification;
  routeTo: string;
};

export default function Notification({
  notification,
  routeTo,
}: NotificationProps) {
  return (
    <div className="flex space-x-2 py-2 sm:space-x-4">
      <div className="h-10 w-10">
        <Avatar
          src={
            notification.type === Collection.SUBSCRIPTIONS
              ? '/images/subscription-info.png'
              : `${CONSTANTS.USERS_AVATARS_BASE_URL}%2F${notification.createdBy?.id}%2Favatar?alt=media`
          }
          className="h-full w-full rounded-full object-cover object-center"
        />
      </div>
      <div className="flex-1 gap-8">
        <div className="flex justify-between text-[13px] font-bold transition duration-500 ease-in-out">
          {routeTo && (
            <Link className=" w-4/5 hover:underline" href={routeTo}>
              <p className="block truncate font-bold dark:text-dark-lighter">
                {notification.title}
              </p>
            </Link>
          )}

          {!routeTo && (
            <p className="block w-4/5 truncate font-bold dark:text-dark-lighter">
              {notification.title}
            </p>
          )}

          <Action notification={notification} />
        </div>

        <div className="inline-block pb-2 text-xs font-normal">
          {notification.body}
        </div>
        <div className="flex items-center justify-between  text-[12px] font-semibold">
          <span className="font-semibold dark:text-dark-lighter">
            {formatDateLabel(notification.createdAt as Timestamp)}
          </span>
          {notification.readStatus && (
            <ReadStatusIcon className="h-[9.06px] w-4 stroke-dark-500 dark:stroke-dark-lighter" />
          )}
        </div>
      </div>
    </div>
  );
}
