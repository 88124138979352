import { UserIcon } from '@heroicons/react/24/outline';
import React from 'react';

export const NotificationSkeleton = () => {
  return (
    <div className="flex items-center justify-between gap-2 md:p-4">
      <div className="flex w-full animate-pulse items-center gap-4">
        <div className="flex h-12 w-12 items-center justify-center rounded-full bg-dark-200 dark:bg-dark-500">
          <UserIcon className="w-5 stroke-dark-300  dark:stroke-dark-600" />
        </div>

        <div className="flex w-1/3 flex-col gap-2">
          <div className="h-4 w-full rounded bg-dark-200 px-2 py-1 dark:bg-dark-500"></div>
          <div className="h-2 w-full rounded bg-dark-200 px-2 py-1 dark:bg-dark-500"></div>
          <div className="h-2 w-12 rounded bg-dark-200 px-2 py-1 dark:bg-dark-500"></div>
        </div>
      </div>

      <div className="flex flex-col justify-between gap-6">
        <div className="h-2 w-12 rounded bg-dark-200 px-2 py-1 dark:bg-dark-500"></div>
        <div className="h-2 w-12 rounded bg-dark-200 px-2 py-1 dark:bg-dark-500"></div>
      </div>
    </div>
  );
};
export default function NotificationListSkeleton() {
  return (
    <div className="flex flex-col gap-6">
      {Array.from({ length: 3 }, (_, count) => (
        <NotificationSkeleton key={count} />
      ))}
    </div>
  );
}
