import React from 'react';

export type EmptyPlaceholderProps = {
  title: string;
  image?: JSX.Element;
  description?: string;
};

export default function EmptyPlaceholder({
  image,
  title,
  description,
}: EmptyPlaceholderProps) {
  return (
    <div className="flex h-full w-full items-center justify-center">
      <div className="flex w-full flex-col items-center justify-center gap-5 text-center md:w-1/2 lg:w-1/3">
        <div>{image}</div>
        <h1 className="text-lg font-semibold">{title}</h1>
        <p className="text-center">{description}</p>
      </div>
    </div>
  );
}
