import { Button } from '@canvaza/serval-ui';
import clsx from 'clsx';
import React from 'react';

type LightBlueButtonProps = {
  title: string | null;
  icon?: JSX.Element;
  rounded?: boolean;
  isLoading?: boolean;
  disabled?: boolean;
  fullWidth?: boolean;
  onClick: () => void;
};
export default function LightBlueButton({
  title,
  icon,
  rounded,
  isLoading,
  disabled,
  fullWidth,
  onClick,
}: LightBlueButtonProps) {
  return (
    <Button
      disabled={disabled}
      isLoading={isLoading}
      variant="contained"
      startIcon={icon || <></>}
      onClick={onClick}
      rounded={rounded}
      fullWidth={fullWidth}
      className={clsx(
        'text-dark-700 dark:text-dark-lighter',
        'bg-primary-lighter/20 hover:bg-primary-lighter/25 dark:bg-primary-lighter/25 dark:hover:bg-primary-lighter/30'
      )}
    >
      {title !== null && (
        <span
          className={clsx(
            'whitespace-nowrap text-dark-600 dark:text-dark-lighter',
            {
              'hidden md:flex': icon,
            }
          )}
        >
          {title}
        </span>
      )}
    </Button>
  );
}
