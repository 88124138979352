/* eslint-disable no-underscore-dangle */
import type { Timestamp } from 'firebase/firestore';
import type { unitOfTime } from 'moment';
import moment from 'moment';

export const formatDateLabel = (at: Timestamp): string => {
  const sec = at?.seconds ?? at?._seconds ?? 0;
  const nano = at?.nanoseconds ?? at?._nanoseconds ?? 0;
  const atInDate = new Date(sec * 1000 + nano / 1000000);

  const getDifference = (unit: unitOfTime.Diff) => {
    return moment().diff(atInDate, unit);
  };

  const seconds = getDifference('seconds');

  const minutes = getDifference('minutes');
  const hours = getDifference('hours');
  const days = getDifference('days');
  const weeks = getDifference('weeks');
  const months = getDifference('months');
  let timeString = 'Just now';

  if (seconds > 0 && seconds <= 60) {
    timeString = `${seconds} seconds ago`;
  } else if (minutes > 0 && minutes <= 60) {
    timeString = `${minutes} minutes ago`;
  } else if (hours > 0 && hours <= 24) {
    timeString = `${hours} hours ago`;
  } else if (days > 0 && days < 7) {
    timeString = `${days} days ago`;
  } else if (weeks > 0 && weeks <= 4) {
    timeString = `${weeks} weeks ago`;
  } else if (months > 0 && months <= 6) {
    timeString = `${months} months ago`;
  } else if (months > 6) {
    timeString = atInDate.toDateString();
  }

  return timeString;
};

export const getMMddYy = (date: Date) => {
  // Get the month, day, and year from the Date object.
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const year = date.getFullYear();

  // Pad the month and day with leading zeros if necessary.
  const paddedMonth = month.toString().padStart(2, '0');
  const paddedDay = day.toString().padStart(2, '0');

  // Create a string from the month, day, and year, separated by dashes.
  const mmddyy = `${paddedMonth}-${paddedDay}-${year}`;

  // Return the string.
  return mmddyy;
};
