import { Button } from '@canvaza/serval-ui';
import { useRouter } from 'next/navigation';
import React from 'react';

import { NOTIFICATIONS } from '@/shared/config/route-links.config';
import type { INotification } from '@/shared/firebase';
import { useBulkUpdateNotifications } from '@/shared/firebase/client/hooks';
import { ReadStatusIcon } from '@/shared/utils/svg';

import type { NotificationType } from '..';

type FooterProps = {
  type: NotificationType;
  notifications: INotification[];
  onCloseDrawer?: () => void;
};
export default function Footer({
  type,
  notifications,
  onCloseDrawer,
}: FooterProps) {
  const router = useRouter();

  const {
    batchUpdate: batchUpdateNotifications,
    state: batchUpdateNotificationsState,
  } = useBulkUpdateNotifications(notifications);

  const handleClearNotification = () => {
    if (notifications.length === 0) return;
    batchUpdateNotifications();
  };
  const handleRouteToNotifications = () => {
    onCloseDrawer!();
    router.push(NOTIFICATIONS.url);
  };
  return (
    <>
      {type === 'dropdown' && (
        <div className="z-20 flex w-full flex-row items-center justify-between gap-5 bg-dark-lighter p-3 sm:gap-0 dark:bg-dark-600">
          <Button
            onClick={handleClearNotification}
            isLoading={batchUpdateNotificationsState.isLoading}
            disabled={
              batchUpdateNotificationsState.isLoading ||
              notifications.length === 0
            }
          >
            <div className="flex flex-row items-center gap-x-3">
              <ReadStatusIcon className="h-[9.06px] w-4 stroke-dark-500 dark:stroke-dark-lighter" />

              <span className="whitespace-nowrap text-xs font-normal text-dark-darker sm:text-sm dark:text-dark-lighter">
                Mark all as read
              </span>
            </div>
          </Button>

          <Button
            variant="contained"
            onClick={handleRouteToNotifications}
            className="h-11 w-44 whitespace-nowrap text-xs font-normal"
          >
            View all
          </Button>
        </div>
      )}
    </>
  );
}
