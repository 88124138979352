import { Menu, MenuItem } from '@canvaza/serval-ui';
import {
  CheckIcon,
  EllipsisHorizontalIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import clsx from 'clsx';
import React from 'react';

import {
  useRemoveNotification,
  useUpdateNotification,
} from '@/shared/firebase/client/hooks/notification.hooks';
import type {
  INotification,
  INotificationUpdate,
} from '@/shared/firebase/models/notification.interface';

type NotificationAction = {
  notification: INotification;
};
export default function Action({ notification }: NotificationAction) {
  const { update } = useUpdateNotification(notification.id);
  const { remove } = useRemoveNotification(notification.id);

  const handleMarkAsRead = () => {
    const newNotification: INotificationUpdate = {
      ...notification,
      readStatus: !notification.readStatus,
    };
    update(newNotification);
  };
  const handleRemove = () => {
    remove();
  };
  return (
    <div className="z-10 flex items-center gap-2">
      <Menu
        placement="bottom-end"
        icon={<EllipsisHorizontalIcon className="w-6" />}
      >
        <div className={clsx('flex w-64 flex-col gap-2 rounded-md p-2')}>
          <MenuItem
            label={`Mark as ${notification.readStatus ? 'unread' : 'read'}`}
            icon={<CheckIcon className="w-6" />}
            className="cursor-pointer rounded-md p-1 hover:bg-dark-200/40 dark:hover:bg-dark-600/30"
            onClick={handleMarkAsRead}
          />

          <MenuItem
            label="Remove this notification"
            icon={<XMarkIcon className="w-6" />}
            className="cursor-pointer rounded-md p-1 hover:bg-dark-200/40 dark:hover:bg-dark-600/30"
            onClick={handleRemove}
          />
        </div>
      </Menu>
    </div>
  );
}
