import type { INotification } from '@/shared/firebase/models/notification.interface';

import Notification from '../Notification';

export default function Item(notification: INotification) {
  return (
    <Notification
      notification={notification}
      routeTo={`/item/${notification.metadata?.objectId}`}
    />
  );
}
